/** @jsx jsx */
import { jsx, Box, Styled, Container, Button } from 'theme-ui';
import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { withFormik } from 'formik';
import * as yup from 'yup';
import * as api from '../utils/api';
import { Form } from 'formik';
import FormField from '../components/FormField';
import { showNotification, SEVERITY, hideAllNotifications } from '../state/notifications';
import { locNavigate } from '../state/session';
import AccountLayout from '../account/AccountLayout';
import * as analytics from '../utils/analytics';

const changePasswordSchema = translate =>
  yup.object().shape({
    oldPassword: yup
      .string()
      .min(6, translate('register.message.tooShort'))
      .required(translate('register.message.requiredField')),
    password: yup
      .string()
      .min(6, translate('register.message.tooShort'))
      .required(translate('register.message.requiredField'))
      .notOneOf([yup.ref('oldPassword')], translate('changePassword.message.samePassword')),
    passwordAgain: yup
      .string()
      .required(translate('register.message.requiredField'))
      .oneOf([yup.ref('password')], translate('register.message.passwordMismatch')),
  });

const ChangePasswordForm = ({ translate, handleSubmit, isSubmitting, setFieldValue }) => {
  return (
    <Form>
      <FormField label={translate('changePassword.form.oldPassword')} type="password" name="oldPassword" />
      <FormField label={translate('register.form.password')} type="password" name="password" />
      <FormField label={translate('register.form.passwordAgain')} type="password" name="passwordAgain" />
      <Box>
        <Button type="submit" onClick={handleSubmit} disabled={isSubmitting}>
          {translate('changePassword.form.changePasswordButton')}
        </Button>
      </Box>
    </Form>
  );
};

const ChangePasswordFormik = withFormik({
  mapPropsToValues: ({ vo }) =>
    Object.assign({
      oldPassword: vo.oldPassword || '',
      password: vo.password || '',
      passwordAgain: vo.passwordAgain || '',
    }),
  validationSchema: ({ translate }) => {
    return changePasswordSchema(translate);
  },
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    onSubmit(values, actions);
  },
  displayName: 'ChangePasswordInputForm',
})(ChangePasswordForm);

export default ({ pageContext }) => {
  analytics.usePageCategory('omat_sivut');
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);
  const [vo, setVO] = useState({});
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (vo, { setSubmitting }) => {
      dispatch(hideAllNotifications());
      try {
        await api.modifyPassword({
          previousPassword: vo.oldPassword.trim(),
          newPassword: vo.password.trim(),
        });
        dispatch(showNotification('changePassword.message.success', SEVERITY.INFO));
        setVO({});
        setTimeout(() => {
          dispatch(locNavigate('/my-account'));
        }, 3000);
      } catch (error) {
        dispatch(showNotification('changePassword.message.changePasswordFailed'));
      }
      setSubmitting(false);
    },
    [dispatch]
  );
  const formProps = { vo, onSubmit, translate };

  return (
    <AccountLayout
      title={translate('changePassword.form.changePasswordButton')}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
    >
      <Container sx={{ maxWidth: 640, py: [3, 4] }}>
        <Styled.h1>{translate('changePassword.form.changePasswordButton')}</Styled.h1>
        <ChangePasswordFormik {...formProps} />
      </Container>
    </AccountLayout>
  );
};
